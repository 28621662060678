import * as types from "./actionTypes";
import ApiList from "../../webservice/ApiList";
import { apiLoadingStart, apiLoadingStop } from "../global";
import { AlertHelper } from "../../Constants/AlertHelper";
export const resetStore = () => ({
  type: types.RESET_STORE_PRODUCT,
});

export const getMessageAction = (uuid) => {
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    dispatch({ type: types.GET_MESSAGE_REQUEST });

    try {
      const response = await ApiList.getMessage(uuid);
      console.log('response getMessageAction',response)
      if (response.status == 200) {
        dispatch({
          type: types.GET_MESSAGE_SUCCESS,
          payload: response?.data?.data,
        });
      } else {
        dispatch({
          type: types.GET_MESSAGE_ERROR,
          payload: response.message,
        });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.GET_MESSAGE_ERROR,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};


export const sendMessageAction = (request) => {
  console.log('request',request)
  return async (dispatch) => {
    dispatch(apiLoadingStart());
    dispatch({ type: types.SEND_MESSAGE_REQUEST });

    try {
      const response = await ApiList.sendMessage(request);
      console.log('send response',response)
      if (response.status == 200) {
        dispatch({
          type: types.SEND_MESSAGE_SUCCESS,
          payload: response?.data?.data,
        });
      } else {
        dispatch({
          type: types.SEND_MESSAGE_ERROR,
          payload: response.message,
        });
        AlertHelper.warning(response.message);
      }
    } catch (error) {
      dispatch({
        type: types.SEND_MESSAGE_ERROR,
        payload: error.message || error,
      });
      AlertHelper.warning(error.message || error);
    } finally {
      dispatch(apiLoadingStop());
    }
  };
};
