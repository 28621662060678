import * as types from "./actionTypes";

const initialState = {
  loading: false,
  errorMessage: "",
  isError: false,
  isRefrash: false,
  userGroups: null,
  userGroupsDetails: null
 
};

const UserGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_STORE_PRODUCT:
      return {
        ...state,
      };

    //end
    case types.GET_USER_GROUP_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.GET_USER_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        isRefrash: false,
        userGroups: action.payload,
      };

    case types.GET_USER_GROUP_ERROR:
      return {
        ...state,
        loading: false,
        isRefrash: false,
        userGroups: null,
        isError: true,
      };

    case types.GET_USER_GROUP_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.GET_USER_GROUP_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        isRefrash: false,
        userGroupsDetails: action.payload,
      };

    case types.GET_USER_GROUP_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        isRefrash: false,
        userGroupsDetails: null,
        isError: true,
      };
    

    default:
      return state;
  }
};

export default UserGroupReducer;
