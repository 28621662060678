import * as types from "./actionTypes";

const initialState = {
  loading: false,
  allUserList: [],
  searchUserList:[],
  filterUserList:[],
  errorMessage: "",
  isError: false,
  isRefrash: false,
  localStorageData: null,
  userManagementDetails: "",
  userManagementDetailsLoading: false,
  userManagementDetailsError: "",
  createUserLoading: false,
  createUserError: "",
};

const SubjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_STORE_PRODUCT:
      return {
        ...state,
      };

    //end
    case types.ALL_USER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.ALL_USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        isRefrash: false,
        allUserList: action.payload,
      };

    case types.ALL_USER_LIST_ERROR:
      return {
        ...state,
        loading: false,
        isRefrash: false,
        allUserList: [],
        isError: true,
      };

    case types.USER_MANAGEMENT_DATAILS_REQUEST:
      return {
        ...state,
        userManagementDetailsLoading: true,
      };

    case types.USER_MANAGEMENT_DETAILS_SUCCESS:
      return {
        ...state,
        userManagementDetailsLoading: false,
        userManagementDetails: action.payload,
      };

    case types.USER_MANAGEMENT_DETAILS_ERROR:
      return {
        ...state,
        userManagementDetailsLoading: false,
        userManagementDetailsError: action.payload,
      };
    case types.CREATE_USER_REQUEST:
      return {
        ...state,
        createUserLoading: true,
      };

    case types.CREATE_USER_SUCCESS:
      return {
        ...state,
        createUserLoading: false,
      };

    case types.CREATE_USER_ERROR:
      return {
        ...state,
        createUserLoading: false,
        createUserError: action.payload,
      };
    case types.SEARCH_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.SEARCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        isRefrash: false,
        searchUserList: action.payload,
      };

    case types.SEARCH_USER_ERROR:
      return {
        ...state,
        loading: false,
        isRefrash: false,
        searchUserList: [],
        isError: true,
      };

      case types.FILTER_USER_REQUEST:
        return {
          ...state,
          loading: true,
        };
  
      case types.FILTER_USER_SUCCESS:
        return {
          ...state,
          loading: false,
          isError: false,
          isRefrash: false,
          filterUserList: action.payload,
        };
  
      case types.FILTER_USER_ERROR:
        return {
          ...state,
          loading: false,
          isRefrash: false,
          filterUserList: [],
          isError: true,
        };

    default:
      return state;
  }
};

export default SubjectReducer;
