export const RESET_STORE_PRODUCT = "RESET_STORE_PRODUCT";

export const ASSIGNMENT_LIST_REFRESH_REQUEST =
  "ASSIGNMENT_LIST_REFRESH_REQUEST";
export const ASSIGNMENT_LIST_SUCCESS = "ASSIGNMENT_LIST_SUCCESS";
export const ASSIGNMENT_LIST_REQUEST = "ASSIGNMENT_LIST_REQUEST";
export const ASSIGNMENT_LIST_ERROR = "ASSIGNMENT_LIST_ERROR";

export const CREATE_ASSIGNMENT_REQUEST = "CREATE_ASSIGNMENT_REQUEST";
export const CREATE_ASSIGNMENT_SUCCESS = "CREATE_ASSIGNMENT_SUCCESS";
export const CREATE_ASSIGNMENT_ERROR = "CREATE_ASSIGNMENT_ERROR";

export const UPLOAD_ASSIGNMENT_REQUEST = "UPLOAD_ASSIGNMENT_REQUEST";
export const UPLOAD_ASSIGNMENT_SUCCESS = "UPLOAD_ASSIGNMENT_SUCCESS";
export const UPLOAD_ASSIGNMENT_ERROR = "UPLOAD_ASSIGNMENT_ERROR";

export const ASSIGNMENT_DETAILS_REQUEST = "ASSIGNMENT_DETAILS_REQUEST";
export const ASSIGNMENT_DETAILS_SUCCESS = "ASSIGNMENT_DETAILS_SUCCESS";
export const ASSIGNMENT_DETAILS_ERROR = "ASSIGNMENT_DETAILS_ERROR";

export const SUBMISSION_LIST_REFRESH_REQUEST =
  "SUBMISSION_LIST_REFRESH_REQUEST";
export const SUBMISSION_LIST_SUCCESS = "SUBMISSION_LIST_SUCCESS";
export const SUBMISSION_LIST_REQUEST = "SUBMISSION_LIST_REQUEST";
export const SUBMISSION_LIST_ERROR = "SUBMISSION_LIST_ERROR";

export const UPDATE_ASSIGNMENT_REQUEST = "UPDATE_ASSIGNMENT_REQUEST";
export const UPDATE_ASSIGNMENT_SUCCESS = "UPDATE_ASSIGNMENT_SUCCESS";
export const UPDATE_ASSIGNMENT_ERROR = "UPDATE_ASSIGNMENT_ERROR";

export const UPDATE_UPLOAD_ASSIGNMENT_REQUEST =
  "UPDATE_UPLOAD_ASSIGNMENT_REQUEST";
export const UPDATE_UPLOAD_ASSIGNMENT_SUCCESS =
  "UPDATE_UPLOAD_ASSIGNMENT_SUCCESS";
export const UPDATE_UPLOAD_ASSIGNMENT_ERROR = "UPDATE_UPLOAD_ASSIGNMENT_ERROR";

export const DELETE_ASSIGNMENT_REQUEST =
  "DELETE_ASSIGNMENT_REQUEST";
export const DELETE_ASSIGNMENT_SUCCESS =
  "DELETE_ASSIGNMENT_SUCCESS";
export const DELETE_ASSIGNMENT_ERROR =
  "DELETE_ASSIGNMENT_ERROR";

export const GET_ASSIGN_PDF_LINK_REQUEST =
  "GET_ASSIGN_PDF_LINK_REQUEST";
export const GET_ASSIGN_PDF_LINK_SUCCESS =
  "GET_ASSIGN_PDF_LINK_SUCCESS";
export const GET_ASSIGN_PDF_LINK_ERROR =
  "GET_ASSIGN_PDF_LINK_ERROR";

  export const STUDENT_GET_ASSIGN_PDF_LINK_REQUEST =
  "STUDENT_GET_ASSIGN_PDF_LINK_REQUEST";
export const STUDENT_GET_ASSIGN_PDF_LINK_SUCCESS =
  "STUDENT_GET_ASSIGN_PDF_LINK_SUCCESS";
export const STUDENT_GET_ASSIGN_PDF_LINK_ERROR =
  "STUDENT_GET_ASSIGN_PDF_LINK_ERROR";


export const ASSIGN_ASSIGNMENT_TO_STUDENT_REQUEST = "ASSIGN_ASSIGNMENT_TO_STUDENT_REQUEST";
export const ASSIGN_ASSIGNMENT_TO_STUDENT_SUCCESS = "ASSIGN_ASSIGNMENT_TO_STUDENT_SUCCESS";
export const ASSIGN_ASSIGNMENT_TO_STUDENT_ERROR = "ASSIGN_ASSIGNMENT_TO_STUDENT_ERROR";


export const APPROVED_ASSIGNMENT_REQUEST = "APPROVED_ASSIGNMENT_REQUEST";
export const APPROVED_ASSIGNMENT_STUDENT_SUCCESS = "APPROVED_ASSIGNMENT_STUDENT_SUCCESS";
export const APPROVED_ASSIGNMENT_STUDENT_ERROR = "APPROVED_ASSIGNMENT_STUDENT_ERROR";

