export const RESET_STORE_PRODUCT = "RESET_STORE_PRODUCT";

export const ALL_USER_LIST_REQUEST = "ALL_USER_LIST_REQUEST";
export const ALL_USER_LIST_SUCCESS = "ALL_USER_LIST_SUCCESS";
export const ALL_USER_LIST_ERROR = "ALL_USER_LIST_ERROR";

export const ASSIGN_COURSE_TO_USER_REQUEST = "ASSIGN_COURSE_TO_USER_REQUEST";
export const ASSIGN_COURSE_TO_USER_SUCCESS = "ASSIGN_COURSE_TO_USER_SUCCESS";
export const ASSIGN_COURSE_TO_USER_ERROR = "ASSIGN_COURSE_TO_USER_ERROR";

export const USER_MANAGEMENT_DATAILS_REQUEST = 'USER_MANAGEMENT_DATAILS_REQUEST';
export const USER_MANAGEMENT_DETAILS_SUCCESS = 'USER_MANAGEMENT_DETAILS_SUCCESS';
export const USER_MANAGEMENT_DETAILS_ERROR = 'USER_MANAGEMENT_DETAILS_ERROR';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR'

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'

export const SEARCH_USER_REQUEST = 'SEARCH_USER_REQUEST'
export const SEARCH_USER_SUCCESS = 'SEARCH_USER_SUCCESS'
export const SEARCH_USER_ERROR = 'SEARCH_USER_ERROR'

export const FILTER_USER_REQUEST = 'FILTER_USER_REQUEST'
export const FILTER_USER_SUCCESS = 'FILTER_USER_SUCCESS'
export const FILTER_USER_ERROR = 'FILTER_USER_ERROR'
